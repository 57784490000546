<template>
  <li class="item-header">Bedienung</li>
  <!-- ANLAGEN UNTERMENU -->
  <li>
    <a class="dropdown-toggle" href="#"
      ><span class="icon"><span class="mif-folder-open"></span></span
      ><span class="caption">Anlagen</span></a
    >
  </li>
  <ul class="navview-menu stay-open" data-role="dropdown">
    <li v-for="menuItem in bedienungMenu" :key="menuItem.shortText">
      <a onclick="mainDesktop('boot')" href="#"
        ><span class="icon"><span class="mif-florist"></span></span
        ><span class="caption">{{ menuItem.titleText }}</span></a
      >
    </li>
  </ul>

  <Panel :header="dragObj.name" :toggleable="true">
    <template #icons>
      <button class="p-panel-header-icon p-link p-mr-2 handle">
        <span class="pi pi-bars"></span>
      </button>
      <button class="p-panel-header-icon p-link p-mr-2" @click="toggle">
        <span class="pi pi-cog"></span>
      </button>
      <Menu id="config_menu" ref="menu" :model="items" :popup="true" />
    </template>
    <div v-for="param in valueObj" :key="param._id" :item="param">
      <div v-if="param.metadata.name" class="p-grid">
        <div class="p-col text-left">
          <p>{{ param.metadata.name }}</p>
        </div>
        <div class="p-col text-left">
          <p>
            {{ param.value.value + " " + param.value.unit }}
          </p>
        </div>
      </div>
    </div>
  </Panel>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "navBarMenu",
  setup() {},
  props: {
    dragObj: {
      type: null,
      required: true,
    },
    valueObj: {
      type: null,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      view_menu: [],
    };
  },
  methods: {
    getMenuList() {
      this.loading = true;
      // SocketioService.getServerData("view", "menu", (response) => {
      //   this.view_menu = response;
      //   Object.entries(this.view_menu).forEach(([, value], index) => {
      //     if (index === 0) {
      //       this.view_menu_selection[0] = "ROOT";
      //     }
      //     this.view_menu_selection[index + 1] = value.label;
      //     this.view_menu_index.push(index);
      //   });
      //   // console.log(this.view_menu_selection);
      //   this.loading = false;
      // });
    },
  },
});
</script>
